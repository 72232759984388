import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export const Name = ({ name = "", varient = "caption", fontWeight = "medium", onClick }) => {
  return (
    <>
      {name !== undefined && name !== null && name !== "" ? (
        <MDTypography variant={varient} fontWeight={fontWeight} onClick={onClick}>
          {String(name)}
        </MDTypography>
      ) : null}
    </>
  );
};

export const Price = ({ price, varient = "caption", fontWeight = "medium" }) => {
  return (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {price && (
        <MDTypography variant={varient} fontWeight={fontWeight}>
          ${price}
        </MDTypography>
      )}
    </MDBox>
  );
};

export const handleSorting = (
  columnId,
  sortDirection,
  currentSortBy,
  currentSortOrder,
  setSortBy,
  setSortOrder,
  columnNameMapping = {}
) => {
  const newSortBy = columnNameMapping[columnId] || columnId;
  const newSortOrder = sortDirection === "desc" ? "DESC" : "ASC";

  if (newSortBy !== currentSortBy || newSortOrder !== currentSortOrder) {
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
  }
};
