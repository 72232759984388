// Admin

export const ADMIN_USER = "admin/user";
export const ADMIN_CREATE_ACCOUNT = "admin/create-account";
export const ADMIN_BRAND = "admin/brand";
export const ADMIN_PRODUCTS = "admin/products";
export const ADMIN_BUYERS = "admin/buyers";
export const ADMIN_LOCATIONS = "admin/locations";
export const ADMIN_SEND_EMAIL_INVITES = "admin/send-email-invites";

// Auth

export const AUTH_LOGIN = "auth/login";
export const AUTH_REGISTER = "auth/register";

// Account

export const ACCOUNTS = "accounts";
export const ACCOUNT_STATE = "accounts/state";

// Brands

export const BRANDS = "brands";
export const BRAND_MONTHLY_BUYERS = "brands/monthly-buyers";
export const BRAND_TOP_SELLING_PRODUCT = "brands/top-selling-product";
export const BRAND_PRODUCT_SALES = "brands/product-sales";

// Buyers

export const BUYERS = "buyers";
export const BUYERS_UPLOAD = "buyers/upload";
export const BUYERS_QUICKBOOKS = "buyers/quickbooks";
export const BUYERS_LOCATIONS = "buyers/locations";

// Distributors

export const DISTRIBUTORS_LIST = "distributors/list";
export const DISTRIBUTOR_TOGGLE_SHOW_PRICE = "distributors/toggle-show-price";

// Sales
export const SALES = "sales";
export const SALES_PRODUCTS = "sales/products";
export const SALES_UPLOAD = "sales/upload";
export const SALES_PRODUCT_SALE = "sales/product-sales";
export const SALES_RECORD = "sales/records";
export const SALES_STATS = "sales/stats";

// Connections

export const CONNECTIONS = "connections";
export const CONNECTIONS_BRAND = "connections/brand";
export const CONNECTIONS_BRAND_DISTRIBUTOR = "connections/brand-distributor";
export const CONNECTIONS_NON_CONNECTED_BRANDS = "connections/nonconnected-brands";
export const CONNECTIONS_NON_CONNECTED_DISTRIBUTORS = "connections/nonconnected-distributors";
export const CONNECTIONS_DISTRIBUTORS = "connections/distributor";
export const CONNECTIONS_REQUEST_BY_ADMIN = "connections/request-by-admin";
export const CONNECTIONS_REQUEST_BY_BRAND = "connections/request-by-brand";
export const CONNECTIONS_REQUEST_BY_DISTRIBUTOR = "connections/request-by-distributor";

// Activity Feed

//Payments

export const PAYMENT = "payment";

// Faire

export const FAIRE_STATUS = "faire/status";
export const FAIRE_REVOKE = "faire/revoke";
export const FAIRE_INVOICES = "faire/invoices";

// Quickbooks

export const QUICKBOOKS = "quickbooks";
export const QUICKBOOKS_AUTH = "quickbooks/auth";
export const QUICKBOOKS_INVOICES_BRAND = "quickbook-invoice/brand";
export const QUICKBOOKS_SAVE_TOKEN = "quickbooks/save-quickbooks-token";

// Products

export const PRODUCTS = "products";
export const PRODUCTS_UPLOAD = "products/upload";
export const PRODUCTS_MERGE = "products/merge";
export const PRODUCTS_DETACH = "products/detach";
export const PRODUCTS_AVAILABLE_PRODUCTS = "products/availableProducts";
export const PRODUCTS_LOW_INVENTORY = "products/lowInventory";
// States

export const STATES = "states";

// Sales Unit
export const SALES_UNIT = "sales-unit";

// Product Sale Units
export const PRODUCT_SALE_UNITS = "product-sales-unit";

// Users

export const USERS = "users";
